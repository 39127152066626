$(document).ready(function () {
    var toggled = $("#toggled");
    if ($(window).width() < 1200) {
        var trigger = $(".page-content");

        trigger.click(function () {
            $(".page-wrapper").removeClass("toggled");
        });
    } else {
        toggled.addClass("toggled");
    }

    $(".sidebar-dropdown > a").click(function () {
        $(".sidebar-submenu").slideUp(200);
        if ($(this).parent().hasClass("active")) {
            $(".sidebar-dropdown").removeClass("active");
            $(this).parent().removeClass("active");
        } else {
            $(".sidebar-dropdown").removeClass("active");
            $(this).next(".sidebar-submenu").slideDown(200);
            $(this).parent().addClass("active");
        }
    });
    $("#close-sidebar").click(function () {
        $(".page-wrapper").removeClass("toggled");
    });
    $("#show-sidebar").click(function () {
        $(".page-wrapper").addClass("toggled");
    });

    $(window).on("beforeunload", function () {
        $("body").removeClass("hide").addClass("show");
    });
});
