$(document).ready(function () {
    // Disable before submit
    $(window).on("beforeunload", function () {
        $(".btnSubmit").prop("disabled", true);
        $(".btnSubmit").html("Please wait for a second");
    });

    // Show Modal
    var myModal = new bootstrap.Modal(document.getElementById("alert"));
    myModal.show();
});
